import { LOCALES } from "../locales"

export default {
	[LOCALES.EN]: {
		// ******************************************************** registration and login
		signIn: "Sign In",
		signUp: "Sign Up",
		save: 'Save',
		email: 'Email: ',
		password: 'Password: ',
		name: 'Name: ',
		enterName: 'Enter Name',
		enterEmail: 'email.com',
		mobilePhone: 'Mobile Phone: ',
		enterMobilePhone: '380...',
		enterPassword: 'Enter Password',
		passwordConfirm: 'Password Confirm: ',
		enterPasswordConfirm: 'Enter Password Confirm',
		nameShop: 'Name {total}: ',
		enterNameShop: 'Enter Naming',
		descriptionShop: 'Description {total}:',
		enterDescriptionShop: 'Enter Description',
		allowYourCustomersVisitOtherStoresOfThisPlatform : ' Allow your customers to visit other stores of this platform?',
		yes: "Yes",
		no: "No",
		iWantShop: 'I want {total}',
		iWantMenu: 'I want {total}',
		ifYouChooseShopYourCustomers: 'If you choose a Shop - your customers will be able to order online!',
		ifYouChooseMenuYourCustomers: 'If you choose a Catalog - your customers will NOT be able to order online!',
		shopLink: '{total} Link: ',
		enterEnterShopLink: 'https://...',
		shopFacebook: '{total} Facebook: ',
		enterEnterShopFacebook: 'https://www.facebook.com/...',
		shopViber: '{total} Viber: ',
		enterEnterShopViber: 'https://invite.viber.com/...',
		shopTelegram: '{total} Telegram: ',
		enterEnterShopTelegram: '@name-user',
		shopInstagram: '{total} Instagram: ',
		enterEnterShopInstagram: 'name account',
		Shop: 'Shop',
		Menu: 'Menu',
		calculateTotalCost: 'Calculate total cost?',
		// ******************************************************** Navbare
		myProducts: 'My products',
		myProfile: 'My Profile',
		contactSupport: 'Contact Support',
		share: 'Share',
		qrCode: 'QR Code',
		copyLink: 'Copy Link',
		linkCopied: 'Link has been copied to clipboard',
		EndTimeSubscriptionPeriod: 'The subscription is valid until',
		// ******************************************************** form validation
		nameIsRequiredField: 'Name is a required field',
		usernameLengthMax: 'Username must be at most 65 characters',
		usernameLengthMin: 'Username must be at min 3 characters',
		mobileIsRequiredField: 'Mobile is a required field',
		mobileNumberIsNotValid: 'Mobile number is not valid',
		emailIsRequiredField: 'Email is a required field',
		emailMustBeValidEmail: 'Email must be a valid email',
		passwordIsRequiredField: 'Password is a required field',
		passwordIsRequiredFieldLengthMin: 'Password must be at min 6 characters',
		passwordConfirmIsRequiredField: 'Password confirm is a required field',
		passwordConfirmIsRequiredFieldLengthMin: 'Password confirm must be at min 6 characters',
		shopNameLengthMax: 'Shop name must be at most 65 characters',
		shopNameLengthMin: 'Shop name must be at min 3 characters',
		shopDescriptionLengthMax: 'Shop description must be at most 3500 characters',
		shopLinkLengthMax: 'Shop link must be at most 65 characters',
		shopLinkLengthMin: 'Shop link must be at min 3 characters',
		pleaseEnterValidShopWebsite: 'Please enter a valid Shop Website',
		pleaseEnterValidFacebookProfile: 'Please enter a valid Facebook profile',
		pleaseEnterValidViberProfile: 'Please enter a valid Viber number',
		pleaseEnterValidTelegramProfile: 'Please enter a valid Telegram profile',
		pleaseEnterValidInstagramProfile: 'Please enter a valid Instagram profile',
		// ******************************************************** Category
		createShopOrMenu: 'Create your Shop or Catalog',
		create: 'Create Category',
		// ******************************************************** Modal
		close: 'Close',
		addCategory: 'Save',
		delete: 'Delete',
		youWantDeleteProduct: 'Are you sure you want to delete: ',
		// ******************************************************** Category
		category: 'Category',
		createCategory: 'Creat Category',
		noCategory: 'No Category',
		categoryList: 'Category list',
		editCategoryName: 'Edit Category Name',
		enterCategoryName: 'Enter category name',
		edit: 'Edit',
		addPhotoCategory: 'Add Photo category',
		payForSubscription: 'Pay for a Subscription',
		// ******************************************************** Card
		product: 'Product',
		createProduct: 'Create Product',
		createProductCard: 'No products',
		editProductCard: 'Edit Product Card',
		deleteProductCard: 'Delete Product Card',
		unitFor: 'Unit for {unit}: ',
		price: 'Price: ',
		available: 'Available: ',
		addPhotoProduct: 'Add Photo product',
		addProductCard: 'Add Product Card',
		nameProduct: 'Name product',
		nameEnterProduct: 'Enter name product',
		enterDescription: 'Product description',
		enterProductDescription: 'Enter product description',
		unitsProduct: 'Units product',
		priceProduct: 'Price product',
		priceEnterProduct: 'Enter price product',
		currencyProduct: 'Currency product',
		availableProduct: 'Available',
		kilogram: 'kilogram',
		gram: 'gram',
		liter: 'liter',
		milliliter: 'milliliter',
		piece: 'piece',
		loremText: 'this is fish text often used in print and web design. Lorem Ipsum has been the standard "fish" for Latin texts since the early 16th century. At the time, an unnamed printer created a large collection of font sizes and shapes, using Lorem Ipsum to print samples. Lorem Ipsum not only successfully survived five centuries without noticeable changes, but also stepped into electronic design.',
		// ******************************************************** Contact Support
		contactSupportMessage: 'If you have any questions, problems and suggestions',
		contactSupportMessage2: 'for improvement - you can write to me.',
		contactSupportTitle: "Questions? Let's Talk",
		// ******************************************************** Profile
		profile: 'Profile',
		editProfile: 'Edit Profile',
		// ******************************************************** HOME
		createAnOnlineShowcaseForYourStore: 'Online showcase',
		createAnOnlineShowcaseForYourStore1: 'of your store.',
		getStarted: 'Get Started',
		startDoing: 'A completely new look with',
		createYourOnlineStoreEasilyStartEarningToday: ' the easiest way to manage your store',
		learnMore: 'Learn more',
		tiredOfNotDoing: 'Why will Theke',
		whatImportant: "help your store grow?",
		yourBusinessNotOnline: 'You will have a convenient and modern website',
		yourCustomerBaseIsNotGrowing: 'It will be convenient for your customers to place orders',
		youCannotQuicklyPresentYourProductClient: 'Your customer base will grow',
		youSpendLotTimeTakingOrdersFromEachClient: 'Processing orders will take a minimum of time',
		theresBetterWay: 'Theke is the best online platform',
		easeOfUse: 'Your customers see only your products and do not visit other stores',
		speed: 'Ease of filling your page - you do not need additional knowledge',
		benefit: 'There are no commissions for selling through the platform',
		fastCommunicationWithClients: 'Permanent support of the technical service',
		seeHowItWorks: 'See How It Works',
		howItWorks: 'How it works?',
		createCategoryFillWithProductCards: 'We register your store by specifying the phone, email and name of the store',
		sendLinkYourStoreYourCustomersConvenientWay: 'Create categories, upload photos, product descriptions and place a link for your customers in the messenger',
		theClientClicksOnYourLinkAndMakesOrderYourStore: 'Receive and send orders to buyers from all over Ukraine and abroad',
		becomeTheke: 'Subscribe to Theke.',
		costMenu: 'Catalog',
		costShop: 'Shop',
		priceThekeMenu: '$25m',
		priceThekeShop: '$39m',
		subscribeThekeAndGetYourBusinessGrowingRightNow: 'Subscribe to Theke and get your business growing right now!',
		viewPlansDetails: 'View Plans Details',
		firstMonthIsFree: 'First month Free!!!',
		customerReviews: 'Customer Reviews',
		whatIsTheke: 'What is Theke?',
		search: 'Search',
		// ******************************************************** QR Code
		qrColorStyle: 'Change QR Color:',
		qrColorBgStyle: 'Change the QR background color:',
		downloadPNG: 'Download PNG',
		createPDF: 'Create PDF',
		downloadPDF: 'Download PDF',
	}
}

